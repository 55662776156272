<template>
  <header
    class="fixed bottom-0 left-0 z-10 flex"
    :style="`width: calc(100% - ${scrollWidth}px);`"
  >
    <div class="w-0 lg:w-16" />
    <div class="flex-1 flex items-center lg:pl-8 lg:pr-5 lg:py-5 lg:bg-white">
      <slot />
    </div>
    <div class="pass-header-toolbar-fix" />
  </header>
</template>


<script>
export default {
  computed: {
    scrollWidth() {
      return this.$store.getters.app.theme.scrollWidth
    },
  },
}
</script>

