export default {
    data: () => ({
        appFeatures: {
            FEATURE_AUTH_VK: 'auth_vk',
            FEATURE_AUTH_FB: 'auth_fb',
        },
    }),
    methods: {
        isFeatureToggled(feature) {
            if(![this.appFeatures.FEATURE_AUTH_VK].includes(feature)) {
                return false
            }

            return Boolean(this.$store.getters.featureToggling.features[feature])
        },

        initFeatureToggling() {
            if (this.$i18n.locale === 'ru')
                this.$store.dispatch('featureToggling/TURN_ON', this.appFeatures.FEATURE_AUTH_VK)
        },
    },
}