<template>
  <header
    class="fixed top-0 left-0 z-10 flex"
    :style="`width: calc(100% - ${scrollWidth}px);`"
  >
    <div class="w-0 lg:w-16" />
    <div
      class="flex-1 flex justify-between items-center pl-5 lg:pl-8 pr-5 bg-white"
      style="height: 72px;"
    >
      <slot />
    </div>
    <div
      class="hidden lg:block pass-header-toolbar-fix"
      :style="`width: calc(486px - ${scrollWidth}px);`"
    />
  </header>
</template>


<script>
export default {
  computed: {
    scrollWidth() {
      return this.$store.getters.app.theme.scrollWidth
    },
  },
}
</script>
