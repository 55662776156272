<template>
  <span class="hs-field-in-step" :class="{'hs-autocomplete':isAutocomplete}">
    <input
      :id="id"
      ref="hs-input"
      :value="value"
      :placeholder="placeholder"
      :style="fullWidth? 'width: 100%;' : ''"
      @input="onInput($event)"
      @keydown="onKeydown($event)"
    >
    <div
      v-if="isAutocomplete && showList"
      :id="id+`autocomplete-list`"
      class="hs-autocomplete-items"
    >
      <div
        v-for="(item, index) in filteredAutocompleteList"
        :key="'el' + index"
        :class="{'hs-autocomplete-active': active===index}"
        @click="select(item.value)"
      >
        {{ item.prepend }}<strong>{{ item.occurrence }}</strong>{{ item.append }}
      </div>
    </div>
  </span>
</template>


<script>
import FieldBaseMixin from './_field-base-mixin.js'
export default {
  mixins: [FieldBaseMixin],
  props: {
    autocomplete: {
      type: [Object, Array],
      default:
        () => ([]),
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showList: false,
      active: -1,
      internalVal: null,
    }
  },
  computed: {
    isAutocomplete(){
      return Array.isArray(this.autocomplete) && this.autocomplete.length
    },
    filteredAutocompleteList() {
      const val = this.internalVal
      if(!val)
        return []
      const matches = this.autocomplete.filter(e => e.toUpperCase().includes(val.toUpperCase()))

      return matches.map(function(e) {
        const pos = e.toUpperCase().indexOf(val.toUpperCase())
        return {
          prepend: e.substr(0, pos),
          occurrence: e.substr(pos, val.length),
          append: e.substr(pos + val.length),
          value: e,
        }
      })
    },
  },
  mounted() {
    const _this = this
    document.addEventListener('click', function() {
      _this.showList = false
    })
  },
  methods: {
    select(val) {
      this.$refs['hs-input'].value = val
      this.setPlainValue(val)
      this.$emit('input', val)
      this.showList = false
    },
    onInput(e) {
      this.$emit('input', e.target.value)

      this.setValue(e)
      this.internalVal = e.target.value
      this.showList = false
      if(!this.internalVal) {
        return false
      }
      if(this.filteredAutocompleteList.length > 0) {
        this.showList = true
      }
    },
    onKeydown(e) {
      if(e.keyCode === 40) {
        // down
        this.active++
        if(this.active >= this.filteredAutocompleteList.length)
          this.active = 0
      } else if(e.keyCode === 38) {
        // up
        this.active--
        if(this.active < -1)
          this.active = -1
      } else if(e.keyCode === 13) {
        // enter
        e.preventDefault()
        if(this.active > -1 && this.filteredAutocompleteList && this.filteredAutocompleteList[this.active]) {
          this.select(this.filteredAutocompleteList[this.active].value)
        }
      } else {
        this.active = -1
      }
    },
  },
}
</script>

<style lang="css">
.hs-autocomplete {
  position: relative;
  display: inline-block;
}

.hs-autocomplete-items {
  position: absolute;
  z-index: 99;
  top: 106%;
  left: 0;
  right: 0;
  font-size: 1.05rem;
  line-height: 1.15rem;
}

.hs-autocomplete-items div {
  padding: 7px;
  cursor: pointer;
  background-color: #fff;
}

.hs-autocomplete-items div:hover {
  background-color: #e9e9e9;
}

.hs-autocomplete-active {
  background-color: rgb(15, 115, 201) !important;
  color: #ffffff;
}
</style>
